// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_q7 d_gv d_cs";
export var heroHeaderCenter = "q_gw d_gw d_cs d_dw";
export var heroHeaderRight = "q_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "q_q8 d_gr d_cw";
export var heroParagraphCenter = "q_gs d_gs d_cw d_dw";
export var heroParagraphRight = "q_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "q_q9 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "q_rb d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "q_rc d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "q_rd d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "q_rf d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "q_rg s_rg";
export var heroExceptionNormal = "q_rh s_rh";
export var heroExceptionLarge = "q_rj s_rj";
export var Title1Small = "q_rk s_rk s_rs s_rt";
export var Title1Normal = "q_rl s_rl s_rs s_rv";
export var Title1Large = "q_rm s_rm s_rs s_rw";
export var BodySmall = "q_rn s_rn s_rs s_rM";
export var BodyNormal = "q_rp s_rp s_rs s_rN";
export var BodyLarge = "q_rq s_rq s_rs s_rP";