// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "p_qY d_bD";
export var storyRowWrapper = "p_hx d_hx d_bK";
export var storyLeftWrapper = "p_qZ d_bz d_bP";
export var storyWrapperFull = "p_q0 d_cD";
export var storyWrapperFullLeft = "p_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "p_mv d_hy";
export var storyLeftWrapperCenter = "p_q1 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "p_q2 d_hF";
export var storyHeader = "p_q3 d_hD d_w d_cs";
export var storyHeaderCenter = "p_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "p_hB d_hB d_by d_dw";
export var storyBtnWrapper = "p_q4 d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "p_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "p_q5 d_fg d_Z";
export var imageWrapperFull = "p_q6 d_w d_H d_bf d_Z";
export var SubtitleSmall = "p_qd s_qd s_rs s_rF";
export var SubtitleNormal = "p_qf s_qf s_rs s_rG";
export var SubtitleLarge = "p_qg s_qg s_rs s_rH";
export var textLeft = "p_dv";
export var textCenter = "p_dw";
export var textRight = "p_dx";